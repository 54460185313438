import { defineNuxtPlugin } from "nuxt/app";
import type { NuxtApp } from "nuxt/app";
import { createI18n } from "vue-i18n";
import enUS from "../locales/en-US.json";
import frFR from "../locales/fr-FR.json";

export default defineNuxtPlugin((nuxtApp: NuxtApp) => {
  const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: "en-us",
    fallbackLocale: "en-us",
    messages: {
      "en-us": enUS,
      "fr-fr": frFR,
    },
  });

  nuxtApp.vueApp.use(i18n);
});
