import { defineNuxtPlugin } from "nuxt/app";
import * as Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsNoData from "highcharts/modules/no-data-to-display";
import Exporting from "highcharts/modules/exporting";
import ExportData from "highcharts/modules/export-data";
import HighchartsVue from "highcharts-vue";
import AccessibilityModule from "highcharts/modules/accessibility";
import OfflineExporting from "highcharts/modules/offline-exporting";

export default defineNuxtPlugin(({ vueApp }) => {
  if (process.client) {
    HighchartsNoData(Highcharts);
    HighchartsMore(Highcharts);
    Exporting(Highcharts);
    ExportData(Highcharts);
    OfflineExporting(Highcharts);
    AccessibilityModule(Highcharts);
    vueApp.use(HighchartsVue);
  }
});
